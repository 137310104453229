exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-contact-success-js": () => import("./../../../src/pages/contact-success.js" /* webpackChunkName: "component---src-pages-contact-success-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-info-js": () => import("./../../../src/pages/info.js" /* webpackChunkName: "component---src-pages-info-js" */),
  "component---src-templates-project-js": () => import("./../../../src/templates/project.js" /* webpackChunkName: "component---src-templates-project-js" */),
  "component---src-templates-work-js": () => import("./../../../src/templates/work.js" /* webpackChunkName: "component---src-templates-work-js" */)
}

